import { React, useState, useEffect, useCallback  } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';
import Spinner from './Spinner';
import TablePagination from '@material-ui/core/TablePagination';
import { useNavigate } from 'react-router-dom';
import Highcharts, { color } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AsoftLogo from './logo2.png'
import AddIcon from '@material-ui/icons/Add';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Mic, MicOff } from '@material-ui/icons';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import { browserName, browserVersion, osName, deviceType } from "react-device-detect";
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AdjustIcon from '@material-ui/icons/Adjust';
// import Typo from 'typo-js';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TypeAnimation } from 'react-type-animation';
import Botzilla from './BotzillaNew1.jpeg'

import quizME from './quiz.jpg'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserQueryImage from './Query.jpeg'
import Advertisment1 from './Advertisment6.jpg'
import Advertisment2 from './Advertisment4.jpeg'

import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },
  opacity:
  {
    pointerEvents:'none',
    opacity:'0.4'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



function AdminDashboard(props) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [event_name,setevent_name]=useState('')
  const [event_desc,setevent_desc]=useState('')
  const [open, setOpen] = useState(false);
  const [opendelete,setopendelete]=useState(false);
  const { window } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userWebWhatsapp_options,setuserWebWhatsapp_options]=useState([])
  const container = window !== undefined ? () => window().document.body : undefined;
  const [filesdata,setfilesdata]=useState("")
  const [eventtype,seteventtype]=useState('')
  const [loader,setLoader]=useState(false)
  const classes = useStyles();
  const [editindex,seteditindex]=useState(100000)
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDelete = () => {
    setopendelete(false);
  };

  
  const handleChange = (event) => {
    seteventtype(event.target.value);
    get_event_details(event.target.value)
  };

  const dropzoneStyles = {
    border: '2px dashed #aaa',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    padding:'20px'
  };

  const [contentAppWebOptions,setcontentAppWebOptions]=useState([])
  const contentAppWeb={
    
    chart: {

        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Content Served By Web / App'
    },
    tooltip: {
        valueSuffix: '%'
    },
    subtitle: {
        text:
        ''
    },
    plotOptions: {
        series: {
             connectorColor: 'rgba(128,128,128,0.5)',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: [{
                enabled: true,
                distance: 20
            }, {
                enabled: true,
                distance: -40,
                format: '{point.percentage:.1f}%',
                style: {
                    fontSize: '1.2em',
                    textOutline: 'none',
                    opacity: 0.7
                },
                filter: {
                    operator: '>',
                    property: 'percentage',
                    value: 10
                }
            }]
        }
    },
    series: [
        {
            name: 'Count',
            colorByPoint: true,
            data: contentAppWebOptions
            // [
            //     {
            //         name: 'Response from Web Content',
            //         y: 400,
            //         color:'#25D366'
            //     },
            //     {
            //         name: 'Response from App Content',
            //         y: 1400,
            //         color:'#8888da'


            //     },
                
            // ]
            
        }
    ]
  }
  const userWebWhatsapp={
    chart: {
        type: 'pie'
    },
    title: {
        text: 'Different Hits By Channels'
    },
    tooltip: {
        valueSuffix: ''
    },
    subtitle: {
        text:
        ''
    },
    plotOptions: {
        series: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: [{
                enabled: true,
                distance: 20
            }, {
                enabled: true,
                distance: -40,
                format: '{point.percentage:.1f}',
                style: {
                    fontSize: '1.2em',
                    textOutline: 'none',
                    opacity: 0.7
                },
                filter: {
                    operator: '>',
                    property: 'percentage',
                    value: 10
                }
            }]
        }
    },
    series: [
        {
            name: 'Count',
            colorByPoint: true,
            data: userWebWhatsapp_options && userWebWhatsapp_options
        }
    ]
}

    const [reqrecbyday,setreqrecbyday]=useState([])
    const [reqrecbydayoption,setreqrecbydayoption]=useState([])

    const [loginbyday,setloginbyday]=useState([])
    const [loginbydayoption,setloginbydayoption]=useState([])

  const userLogin={
    chart: {
        type: 'column'
    },
    title: {
        text: 'Last 7 Days User Login Count',
        align: 'left',
        style: {
            paddingLeft: '20px' // Adjust the padding value as needed
        }
    },
    subtitle: {
        text:
            '',
        align: 'left'
    },
    xAxis: {
        categories: loginbyday,
        crosshair: true,
        accessibility: {
            description: ''
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Count of Users'
        }
    },
    tooltip: {
        valueSuffix: ''
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [
       
        {
            name: 'Users Count',
            data: loginbydayoption
        }
    ]
}

const userQueryDay={
    chart: {
        type: 'column'
    },
    title: {
        text: 'Request Received by Day Wise',
        align: 'left'
    },
    subtitle: {
        text:
            '',
        align: 'left'
    },
    xAxis: {
        categories: reqrecbyday,
        crosshair: true,
        accessibility: {
            description: ''
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: ' Request Count'
        }
    },
    tooltip: {
        valueSuffix: ''
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [
       
        {
            name: 'Users Count',
            data: reqrecbydayoption
        }
    ]
}

  const options = {
    title: {
      text: 'My chart'
    },
    series: [{
      data: [1, 2, 3]
    }]
  }

  const handleDropzoneChange = (files) => {
    // Filter out files that are not JPEG or PNG
    const filteredFiles = files.filter(file => file.type === 'image/png');

    // If more than 2 files are selected, keep only the first 2
    const limitedFiles = filteredFiles.slice(0, 1);

    // Handle the files (e.g., upload or display)
    console.log(limitedFiles);
    console.log(limitedFiles[0]);

    setfilesdata(limitedFiles[0])
  };

  function updateweeklydetails(e,columns_name,index)
  {
    let weektest=[...weeklyupdates]
    if (columns_name=="flag")
      {
        console.log(e.target.checked)
        weektest[index][columns_name]=e.target.checked
      }
    else
    {
      weektest[index][columns_name]=e.target.value
    }
    setweeklyupdates(weektest)
  }
  function editupdates(e,row,index)
  {
    seteditindex(index)
  }

  function storeWeeklyEvent(e)
  {
    setLoader(true)
    let data={"event_name":event_name,"event_desc":event_desc,"event_type":eventtype}
    axiosService.post("addweeklyevent",data).then(
      response => 
      { 
          setLoader(false)
          seteditindex(100000)
          setsuccessopen(true)
          setsuccessopenmsg("Record Added Successfully")
          get_event_details(eventtype)
          setOpen(false)
          setevent_name('')
          setevent_desc('')
      }
      )
      .catch(error => 
          {
              console.log("error")
              // setfilesdata("")
          // setadvertisment("")
          setLoader(false)
          setsuccessopen(true)
          setsuccessopenmsg("Something Went Wrong")
          }
      )
  }

  useEffect(()=>
    {
        getAnalytics() 
    },[]
    )

  const [eventrow,seteventrow]=useState()

  function showconfirmDialog(e,row,index)
  {
    setopendelete(true)
    seteventrow(row)
  }
  
  function getAnalytics(e)
  {
    setLoader(true)
    axiosService.get("getanalytcs").then(
      response => 
      { 
          setLoader(false)
          setuserWebWhatsapp_options(response.data.userWebWhatsapp)
          setcontentAppWebOptions(response.data.userContentweb)
          setreqrecbyday(response.data.reqrecbyday)
          setreqrecbydayoption(response.data.reqrecbydayoption)
          setloginbyday(response.data.loginbyday)
          setloginbydayoption(response.data.loginbydayoption)
      }
      )
      .catch(error => 
          {
              console.log("error")
              // setfilesdata("")
          // setadvertisment("")
          setLoader(false)
          }
      )

  }
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('role');

    navigate('/login');
  };


  const [successopen,setsuccessopen]=useState(false)
  const [successopenmsg,setsuccessopenmsg]=useState('')

  const handleClosesnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setsuccessopen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' });
  const [weeklyupdates,setweeklyupdates]=useState([])

  function updateDetails(e,row,eventtype)
  {

    setLoader(true)
    axiosService.post("updateweeklyfuture",row).then(
      response => 
      {
        
        
          setLoader(false)
          seteditindex(100000)
          get_event_details(eventtype)
          setsuccessopen(true)
          setsuccessopenmsg("Record Updated Successfully")
      }
      )
      .catch(error => 
          {
              console.log("error")
              // setfilesdata("")
          // setadvertisment("")
          setLoader(false)
          }
      )

  }
  function get_event_details(event_type)
  {
   
    setLoader(true)
    axiosService.get("weeklyfuture?event_type="+event_type).then(
      response => 
      {
        setweeklyupdates(response.data.query_response)
          setLoader(false)
          seteditindex(100000)
      }
      )
      .catch(error => 
          {
              console.log("error")
              // setfilesdata("")
          // setadvertisment("")
          setLoader(false)
          }
      )

  }
  const drawer = (
    <div>
      {loader && <Spinner></Spinner>}
    <div  />
    <Grid item container lg={12} sm={12} md={12} style={{display:'flex',cursor:'pointer',minHeight:'570px'}} >
    <Grid item lg={12} md={12} sm={12}><Link to="/botzilla" style={{paddingLeft:'50px',paddingBottom:'10px',fontSize:'19px'}} >Back to chat bot</Link></Grid>
    
    </Grid>
    <Divider />


    
    


    
    <Divider />
    
    <div style={{ flexGrow: 1 }} />
    {/* <Typography style={{fontWeight:'bolder',paddingLeft:'10%'}}>Powered By </Typography> */}
    {/* <h6>Powered By</h6> */}
    <ListItem button >
      
    <img src={AsoftLogo} style={{width:'50%',height:'10%'}}></img>
    </ListItem>
         {/* <AccountCircle></AccountCircle> 
         <ListItemText style={{paddingLeft:'10px'}} primary="" />
         
        </ListItem> */}
        <ListItem button >
        <ExitToAppIcon></ExitToAppIcon>
        <ListItemText style={{paddingLeft:'10px'}} primary="Logout" onClick={handleLogout} />
         </ListItem>
    <Divider />
    
  </div>
);

  return (
    <Grid item container lg={12} sm={12} md={12} style={{paddingTop:'5%'}}>


<Dialog
        open={opendelete}
        keepMounted
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Event Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          
          Are you sure you want to delete the event ?
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={6} sm={6} md={6} style={{display:'grid',padding:'10px'}}>
          <Button onClick={(e)=>deleteupdates(e)} variant="contained" color="primary"  style={{backgroundColor:'green'}}>
            Ok
          </Button>
          
          </Grid>
          <Grid item container lg={6} sm={6} md={6} style={{display:'grid',padding:'10px'}}>
          <Button onClick={handleCloseDelete} variant="contained" color="secondary">
            Cancel
          </Button>
          
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>



<Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Add Weekly / Future Updates Records "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          
          <Grid item container lg={12} sm={12} md={12} style={{padding:'20px'}}>
          <TextField id="outlined-basic" label="Event Name" variant="outlined" value={event_name} onChange={(e)=>setevent_name(e.target.value)}/>

          </Grid>

          <Grid item container lg={12} sm={12} md={12} style={{padding:'20px'}}>
          <TextField id="outlined-basic" label="Event Description" variant="outlined" value={event_desc} onChange={(e)=>setevent_desc(e.target.value)}/>

          </Grid>

          
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
         <Button onClick={(e)=>storeWeeklyEvent(e)} variant="contained" color="primary"
         
         className={clsx(classes.margin,{
          [classes.opacity]:(event_name.length==0 || event_desc.length==0)
        })}
        
         
         >
            Submit
          </Button>
          
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>


      <Snackbar open={successopen} autoHideDuration={2000} onClose={handleClosesnackbar}   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
        <Alert onClose={handleClosesnackbar} severity="success">
          {successopenmsg}
        </Alert>
      </Snackbar>
            
    

      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/* <p style={{display:'contents'}}><img src={Botzilla} style={{width:'4%'}}></img></p>  */}
          <Typography variant="h6" noWrap style={{paddingLeft:'10px',display:'contents'}}>
         {/* BOTZILLA */}
         <img src={Botzilla} style={{width:'10%',height:'20%'}}></img>

          </Typography>
         
          {/* <img src={Botzilla} style={{width:'5%',height:'20%',marginLeft:'85%'}}></img> */}
         
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, 
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      

     
      <Grid item container lg={12} md={12} sm={12} style={{display:'flex'}}>
       
      <Grid item container lg={5} md={5} sm={5} style={{display:'block',marginLeft:'15%',paddingLeft:'10px',marginBottom:'1%'}}>
      <HighchartsReact
    highcharts={Highcharts}
    options={userQueryDay}
  />
      </Grid>

      

      <Grid item container lg={5} md={5} sm={5} style={{display:'block',marginBottom:'1%'}}>
      <HighchartsReact
    highcharts={Highcharts}
    options={userWebWhatsapp}
  />
      </Grid>
       
      <Grid item container lg={5} md={5} sm={5} style={{display:'block',marginLeft:'15%',paddingLeft:'10px',marginBottom:'1%'}}>
      <HighchartsReact
    highcharts={Highcharts}
    options={contentAppWeb}
  />
      </Grid>

      <Grid item container lg={5} md={5} sm={5} style={{display:'block',marginBottom:'1%'}}>
      <HighchartsReact
    highcharts={Highcharts}
    options={userLogin}
  />
      </Grid> 
      </Grid>

      

            
      
          

          {/* <Grid item container lg={12} md={12} sm={12} style={{display:'flex'}}>
      <Grid item lg={5} md={5} sm={5} style={{display:''}}>
          
        </Grid>
        <Grid item lg={6} md={6} sm={6} style={{display:'',paddingLeft:'30px',paddingTop:'40px'}}>
                  <Button variant="contained" color="primary"
                  
                  className={clsx(classes.margin,{
                    [classes.opacity]:(advertisment=='' || filesdata=='')
                  })}
                  onClick={(e)=>storeAdvertismentImage(e)}
                  >
            Submit
          </Button>
          </Grid>
          </Grid> */}
      
    </Grid>
  );
}
AdminDashboard.propTypes = {
  window: PropTypes.func,
};
export default AdminDashboard;
