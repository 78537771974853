import { React, useState, useEffect,useRef  } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';
import Spinner from './Spinner';
import AsoftLogo from './logo2.png'
import { Mic, MicOff, ThumbsUpDownOutlined } from '@material-ui/icons';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import { browserName, browserVersion, osName, deviceType } from "react-device-detect";
import { Link } from 'react-router-dom';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';import AdjustIcon from '@material-ui/icons/Adjust';
// import Typo from 'typo-js';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TypeAnimation } from 'react-type-animation';
import Botzilla from './BotzillaNew1.jpeg'
import Botzilla1 from './BotzillaNew2.jpeg'

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import quizME from './quiz.jpg'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserQueryImage from './Query.jpeg'
import Advertisment1 from './Advertisment6.jpg'
import Advertisment2 from './Advertisment4.jpeg'

import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  opacity:
  {
    pointerEvents:'none',
    opacity:'0.4'
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));


function ResponsiveDrawer(props) {

  
  const { window } = props;
  const [loader,setLoader]=useState(false)
  // const dictionary = new Typo('en_US');
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });
  const [hoverindex,sethoverindex]=useState('')
  const [hoverindexQuestion,sethoverindexQuestion]=useState('')
  const [editiconindex,setediticonindex]=useState('')
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [Disclaimer,setDisclaimer]=useState(false);
  const [opengame, setopengame] = useState(false);
  const [quizquestion,setquizquestion]=useState([])

  const [quizquestionog,setquizquestionog]=useState([])
  const [openfuture,setopenfuture]=useState(false)
  const [chatgptresponse,setchatgptresponse]=useState([])
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userQuery,setuserQuery]=useState('')
  const [randomNmber,setrandomNmber]=useState(Math.floor(Math.random() * 10000001))
  const [provideAnimation,setprovideAnimation]=useState(false);
  const [frequentlyaskedQuestion,setfrequentlyaskedQuestion]=useState([])
  const [historyData, sethistoryData] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('role');

    navigate('/login');
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const containerRef = useRef(null);


  const handleClickOpenfuture = () => {
    setopenfuture(true);
  };

  
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDisclaimer = () => {
    setDisclaimer(false);
  };

  const handleClosefuture = () => {
    setopenfuture(false);
  };


  const handleClosegame = () => {
    setopengame(false);
  };

  function updateLike(e,row,like_status)
  {
      
   
    setLoader(true)
    axiosService.get("updatechatlikedislikebyid?chat_id="+row.chat_id+"&unique_chat_id="+row.unique_chat_id+"&answer_response="+like_status).then(
            response => 
            {
                setchatgptresponse(response.data.query_response)
                setLoader(false)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
      
  }


  function updateDisLike(e,row,like_status)
  {
    
    
   
    setLoader(true)
    axiosService.get("updatechatlikedislikebyid?chat_id="+row.chat_id+"&unique_chat_id="+row.unique_chat_id+"&answer_response="+like_status).then(
            response => 
            {
                setchatgptresponse(response.data.query_response)
                setLoader(false)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
      

  }

//   function displayText(text) {

//     const words = text && text.split(' ');
//     const lines = [];
//     let line = '';
  
//     words && words.forEach(word => {
//       if ((line + ' ' + word).length > 100) {
//         lines.push(line.trim());
//         line = word;
//       } else {
//         line += ' ' + word;
//       }
//     });
//     if (line !== '') {
//       lines.push(line.trim());
//     }
//     return (
//       <div >
//         {lines.map((line, index) => (
//   <div key={index} style={{overflow:'hidden'}}>
//     {line.split(' ').map((word, i) => (
//       word.startsWith('http') ? (
//         <a key={i} href={word} style={{color:'blue',overflow:'hidden'}}target="_blank" rel="noopener noreferrer">Source Website</a>
//       ) : (
//         <span key={i}>{word} </span>
//       )
//     ))}
//   </div>
// ))}
//       </div>
//     );
//   }


function displayText(text) {
  // Regular expression to find URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text by URLs and map it to JSX elements
  const parts = text.split(urlRegex).filter(part => part.trim() !== '').map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <a key={index} href={part} target="_blank" style={{ color: 'blue' }} rel="noopener noreferrer">
          Source Website
        </a>
      );
    }
    return part;
  });

  return <div>{parts}</div>;
}

  function downloadfilechat(e)
  {
    setAnchorEl(null)
    setLoader(true)
    
    axiosService.get("downloadHistoryExcelFile?date="+new Date(), {
      responseType: 'blob'
    }).then(
            response => 
            {
              setLoader(false)
              const blob = new Blob([response.data]);
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.setAttribute('download', 'Last7DaysChatHistory.xlsx');
                a.style.display = 'none';
                document.body.appendChild(a);
                
                a.click();
            }
            )
            .catch(error => 
                {
                    console.log("error",error)
                }
            )
  }
  function downloadfile(e)
  {
    
    setAnchorEl(null)
    setLoader(true)
    
    axiosService.get("downloadExcelFile?date="+new Date(), {
      responseType: 'blob'
    }).then(
            response => 
            {
              setLoader(false)
              const blob = new Blob([response.data]);
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.setAttribute('download', 'BotzillaAnalytics.xlsx');
                a.style.display = 'none';
                document.body.appendChild(a);
                
                a.click();
            }
            )
            .catch(error => 
                {
                    console.log("error",error)
                }
            )
  }
  function call_data(user_question)
  {
    console.log("location",location.latitude)
    console.log("longitude",location.longitude)

    setLoader(true)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdf?user_query="+user_question+"&chat_id="+randomNmber+"&browserName="+browserName+"&osName="+osName+"&deviceType="+deviceType+"&latitude="+location.latitude+"&longitude="+location.longitude+"&username="+localStorage.getItem("username")).then(
            response => 
            {
                temp_records.push(
                    {
                        'question':user_question,
                        'answer':response.data.query_response,
                        'chat_id':response.data.chat_id,
                        'answer_response':response.data.answer_response,
                        'unique_chat_id':response.data.unique_chat_id

                    }
                ) 
                setLoader(false)
                setchatgptresponse(temp_records)
                setshowsuggestionupdates(false)

    setuserQuery('')
    setprovideAnimation(true)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                    setshowsuggestionupdates(false)

                }
            )
  }
  function storeHisory(row)
  {
    callHstory()
    setprovideAnimation(false)
    setchatgptresponse([])
    setLoader(true)
    setrandomNmber(row.chat_id)
    axiosService.get("getrecordsbyid?chat_id="+row.chat_id).then(
            response => 
            {
                setchatgptresponse(response.data.query_response)
                setLoader(false)
                setshowsuggestionupdates(false)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                    setshowsuggestionupdates(false)

                }
            )
  }

  function deleteHistory(e,row)
  {
    setchatgptresponse([])
    deleteHistoryData(row.chat_id)
    // callHstory()
  }
  
  function handleTextChange(e)
  {
    // const newText = event.target.value;
    // const correctedText = newText
    //   .split(' ')
    //   .map((word) => dictionary.check(word) ? word : dictionary.suggest(word)[0])
    //   .join(' ');
    setuserQuery(e.target.value);
  };
  
  

  function handleKeyPressEdit(event,index,user_query)
  {
    if (event.key === 'Enter') {
      event.preventDefault()
      // Check if the Shift key is also pressed
      if (event.shiftKey) {
        // Do nothing if Shift+Enter is pressed
        return;
      }

      // Call your function here if only Enter is pressed
      callApiEdit(event,index,user_query)
      
    }
  }

  
  function calluserdetails(e,row)
  {
    setshowsuggestionupdates(false)
    
    
    setLoader(true)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdf?user_query="+row.updates+"&chat_id="+randomNmber+"&browserName="+browserName+"&osName="+osName+"&deviceType="+deviceType+"&latitude="+location.latitude+"&longitude="+location.longitude+"&username="+localStorage.getItem("username")).then(
            response => 
            {
                temp_records.push(
                    {
                        'question':row.updates,
                        'answer':response.data.query_response,
                        'chat_id':response.data.chat_id,
                        'answer_response':response.data.answer_response,
                        'unique_chat_id':response.data.unique_chat_id
                    }
                ) 
                setLoader(false)
                setchatgptresponse(temp_records)
    setuserQuery('')
    setprovideAnimation(true)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
    
    
  }
  function handleKeyPress(event)
  {
    if (event.key === 'Enter') {
      event.preventDefault()
      // Check if the Shift key is also pressed
      if (event.shiftKey) {
        // Do nothing if Shift+Enter is pressed
        return;
      }

      // Call your function here if only Enter is pressed
      callApi(event)
      setuserQuery('')
      setshowsuggestionupdates(false)
      
    }
  }

  function storeValue(e)
  {
    
  }

  
  const smoothScrollToBottom = (element) => {
    const targetPosition = element.scrollHeight;
    const startPosition = element.scrollTop;
    const distance = targetPosition - startPosition;
    const duration = 500; // duration in ms
    const interval = 20; // interval in ms
    const stepCount = duration / interval;
    let stepIndex = 0;

    const easeInOutCubic = (t) => {
      return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    };

    const smoothStep = () => {
      if (stepIndex < stepCount) {
        stepIndex += 1;
        const progress = stepIndex / stepCount;
        element.scrollTop = startPosition + distance * easeInOutCubic(progress);
        setTimeout(smoothStep, interval);
      }
    };

    smoothStep();
  };
  
  function callApi(e)
  {
    setLoader(true)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdf?user_query="+userQuery+"&chat_id="+randomNmber+"&browserName="+browserName+"&osName="+osName+"&deviceType="+deviceType+"&latitude="+location.latitude+"&longitude="+location.longitude+"&username="+localStorage.getItem("username")).then(
            response => 
            {
                temp_records.push(
                    {
                        'question':userQuery,
                        'answer':response.data.query_response,
                        'chat_id':response.data.chat_id,
                        'answer_response':response.data.answer_response,
                        'unique_chat_id':response.data.unique_chat_id
                    }
                ) 
                setLoader(false)
                setchatgptresponse(temp_records)
                
                if (containerRef.current) {
                  setTimeout(() => {
                    smoothScrollToBottom(containerRef.current);
                  }, 1000);
                }
    setuserQuery('')
    setprovideAnimation(true)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
    
  }

  function callApiEdit(event,index,user_query)
  {
    setLoader(true)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdfedit?user_query="+user_query+"&chat_id="+randomNmber+"&old_question="+old_question+"&browserName="+browserName+"&osName="+osName+"&deviceType="+deviceType+"&latitude="+location.latitude+"&longitude="+location.longitude).then(
            response => 
            {
                // temp_records.push(
                //     {
                //         'question':userQuery,
                //         'answer':response.data.query_response
                //     }
                // ) 
                temp_records[index]['answer']=response.data.query_response
                setLoader(false)
                setchatgptresponse(temp_records)
    setediticonindex('')
    setprovideAnimation(false)
    setold_question(user_query)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
    
  }


  function callApitranslate(index,oldanswer,language,oldquestion)
  {
    setAnchorE2(null)
    setLoader(true)

    let data={
      'user_language':language,
      'chat_id':randomNmber,
      'oldanswer':oldanswer,
      'old_question':oldquestion

    }
    let temp_records=[...chatgptresponse]
    axiosService.post("chatwithpdftranslate",data).then(
            response => 
            {
              temp_records[index]['answer']=response.data.query_response
                setLoader(false)
                temp_records[index]['answer']=response.data.query_response
                setLoader(false)
                setchatgptresponse(temp_records)
                setediticonindex('')
                setprovideAnimation(false)
                setold_question(oldquestion)

            }
            )
            .catch(error => 
                {
                    console.log("error")
                    setAnchorE2(null)

                }
            )
    
  }


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSpeechEnd = () => {
    // Preserve the transcript when speech ends
    setuserQuery(transcript);
  };
  const [isListening, setIsListening] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition({
   
  });

  const handleListen = () => {
    setIsListening(!isListening);
  };

  
  useEffect(() => {
    SpeechRecognition.onEnd = handleSpeechEnd; // Set up onEnd callback
    

  }, []); 
 
  

  const [buttonLabel,setbuttonLabel]=useState('')
  
  const [buttonshow,setbuttonshow]=useState('')
  function showcolor(option,answer)
  {
    

    if ( option.length>0)
    {
      if (option===answer)
      {
        setbgcolor('green')
        setbuttonshow(answer)
      }
      else
      {
        setbgcolor('green')
        setbuttonshow(answer)
      }
      
    }

    setTimeout(() => {
      setopengame(false)
    }, 2000);
  }


  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setError(null);  // Clear any previous errors
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          getLocation();
        } else if (result.state === 'prompt') {
          getLocation();
        } else if (result.state === 'denied') {
          setError('Geolocation permission denied. Please enable location services in your browser settings.');
        }

        result.onchange = () => {
          if (result.state === 'granted') {
            getLocation();
          }
        };
      });
    } else {
      // Fallback for browsers that don't support the Permissions API
      getLocation();
    }
  }, []);


  const [anchorEl, setAnchorEl] =useState(null);

  const [anchorE2, setAnchorE2] =useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const handleCloseAnchor1 = () => {
    setAnchorE2(null);
  };


  const open1 = Boolean(anchorEl);

  const open2 = Boolean(anchorE2);

  const id = open1 ? 'simple-popover' : undefined;

  const id1 = open2 ? 'simple-popover' : undefined;



  const [bgcolor,setbgcolor]=useState('')
  


  function generateNewChat()
  {
    setchatgptresponse([])
    setrandomNmber(Math.floor(Math.random() * 100001))
    callHstory()
    setprovideAnimation(true)
    setshowsuggestionupdates(true)
  }

  function showGame()
  {
    let no=Math.floor(Math.random() * (9 - 0 + 1) + 0)
    let all_data=[]
    
    
    all_data.push(quizquestionog[no])
    setquizquestion(all_data)
   
      setopengame(true)

  
  }
  function callHstory()
  {
    setLoader(true)
    axiosService.get("historyrecords").then(
        response => 
        {
           sethistoryData(response.data.query_response)
            setLoader(false)

        }
        )
        .catch(error => 
            {
                console.log("error")

            }
        )
  }

  function mostPopular()
  {
    setLoader(true)
    axiosService.get("mostpopular").then(
        response => 
        {
           setfrequentlyaskedQuestion(response.data.query_response)
            setLoader(false)
        }
        )
        .catch(error => 
            {
                console.log("error")

            }
        )
  }

  function getAdvertismentImage()
  {
    setLoader(true)
    axiosService.get("getadvImg").then(
        response => 
        {
           setfirstadvertisment1('data:image/png;base64,'+response.data.image_data1)
           setfirstadvertisment2('data:image/png;base64,'+response.data.image_data2)

            setLoader(false)
        }
        )
        .catch(error => 
            {
                console.log("error")
                setLoader(false)
            }
        )
  }

  function getEventDetails()
  {
    setLoader(true)
    axiosService.get("eventdetails").then(
        response => 
        {
          setweeklyupdates(response.data.weekly_details)
          setfutureupdates(response.data.yearly_details)
            setLoader(false)
        }
        )
        .catch(error => 
            {
                console.log("error")
                setLoader(false)
            }
        )
  }

  function getHomePageDetails()
  {
    setLoader(true)
    axiosService.get("homepage").then(
        response => 
        {
          setsuggestionupdates(response.data.query_response)
          
            setLoader(false)
        }
        )
        .catch(error => 
            {
                console.log("error")
                setLoader(false)
            }
        )
  }

  function quizDetails()
  {
    setLoader(true)
    axiosService.get("quizdetails").then(
        response => 
        {
          //  setquizquestion(response.data.query_response)
           setquizquestionog(response.data.query_response)
            setLoader(false)
    let no=Math.floor(Math.random() * (9 - 0 + 1) + 0)
    let all_data=[]
    all_data.push(response.data.query_response[no])
    setquizquestion(all_data)
    // setTimeout(() => {
    //   setopengame(true)
    // }, 2000);
        }
        )
        .catch(error => 
            {
                console.log("error")

            }
        )
  }


  function setnewanswer(e,question,index)
  {
    let oldresponse=[...chatgptresponse]
    chatgptresponse[index]['question']=e.target.value
    setchatgptresponse(oldresponse)
  }
  
  function sethoverindexresponse(index)
  {
    sethoverindex(index)
    sethoverindexQuestion('')
    setediticonindex('')
  }

  const [firstadvertisment1,setfirstadvertisment1]=useState('')
  const [firstadvertisment2,setfirstadvertisment2]=useState('')

  const [old_question,setold_question]=useState('')
  function setediticonindexquestion(index,question)
  {
    setediticonindex(index)
    sethoverindexQuestion('')
    sethoverindex('')
    setold_question(question)
  }

  function deleteHistoryData(chat_id)
  {
    setLoader(true)
    axiosService.get("deletehistoryrecordsbyid?chat_id="+chat_id).then(
        response => 
        {
            callHstory()
            // setLoader(false)
        }
        )
        .catch(error =>  
            {
                console.log("error")
            }
        )
  }
  useEffect(()=>
  {
      callHstory()
      mostPopular()
      quizDetails()
      getAdvertismentImage()
      getEventDetails()
      getHomePageDetails()
  },[]
  )

  // useEffect(()=>
  // {
  //     showGame()
  // },[]
  // )

  function translateData(index,language,answer,old_question)
  {
    
    callApitranslate(index,answer,language,old_question)
  }

  useEffect(() => {
    if (transcript!=='')
    {
      
      setuserQuery(transcript);
    }
    
  }, [transcript])


  useEffect(() => {
    if (isListening) {
      SpeechRecognition.startListening();
    } else {
      SpeechRecognition.stopListening();
    }})
  

  const drawer = (
      <div>
        {loader && <Spinner></Spinner>}
      <div  />
      <Grid item container lg={12} sm={12} md={12} style={{display:'flex',cursor:'pointer'}} >

      <Tooltip title="">
                  <Grid item>
                    {localStorage.getItem("role")=="admin" && 
                    
                    <Link to="/adminhomescreen" style={{paddingLeft:'5px'}}><SupervisorAccountIcon ></SupervisorAccountIcon></Link>
                    
                    // <MoreVertIcon  aria-describedby={id} onClick={handleClick} fontSize='small' style={{cursor:'pointer',float:'inline-end'}}></MoreVertIcon>
                    
                    }
                 
      <Popover
        id={id}
        open={open1}
        anchorEl={anchorEl}
        onClose={handleCloseAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
      <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex',textDecoration:'underline'}}> <Link to="/advertisement" style={{color:'blue'}}> Update Advertisement </Link></Grid> 
      <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex',textDecoration:'underline'}}> <Link to="/weeklyupdates" style={{color:'blue'}}>Weekly / Future Updates</Link></Grid> 

      <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex',textDecoration:'underline',color:'blue',cursor:'pointer'}} onClick={(e)=>downloadfile(e)}>Generate Analytics</Grid> 
      <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex',textDecoration:'underline',color:'blue',cursor:'pointer'}} onClick={(e)=>downloadfilechat(e)}>Last 7 Days ChatHistory</Grid> 
      <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex',textDecoration:'underline',color:'blue',cursor:'pointer'}} ><Link to="/uploadfile" style={{color:'blue'}}>Upload Documents</Link></Grid> 
      <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex',textDecoration:'underline',color:'blue',cursor:'pointer'}} ><Link to="/admindashboard" style={{color:'blue'}}>Admin Dashboard</Link></Grid> 

       {/* <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex'}}> <SupervisorAccountIcon fontSize='medium' style={{paddingTop:'0px'}}/><Grid item container lg={4} sm={4} md={4} style={{paddingLeft:'30px',paddingTop:'3.5px'}} ><Link to="/advertisement">Update Advertisment </Link> </Grid></Grid> */}
       {/* <Grid item container lg={12} sm={12} md={12} style={{padding:'10px',display:'flex'}}> <Grid item container lg={1} sm={1} md={1} ><SupervisorAccountIcon fontSize='medium' style={{paddingTop:'0px'}}/></Grid> <Grid item container lg={4} sm={4} md={4} style={{paddingLeft:'30px',paddingTop:'3.5px'}} ><Link to="/advertisement">Weekly / Future </Link> </Grid></Grid> */}
     
              </Popover>
    </Grid>
    </Tooltip>

      {/* <Link to="/advertisement"> <Grid lg={1} md={1} sm={1} style={{paddingLeft:'10px'}}><SupervisorAccountIcon fontSize='medium' style={{paddingTop:'0px'}}/> </Grid></Link> */}
        
        
        <Grid item lg={7} md={7} sm={7}><Typography style={{paddingLeft:'50px',paddingBottom:'10px'}} >
        

          New Chat</Typography></Grid>
      <AddCircleIcon onClick={(e)=>generateNewChat(e)}/>
      <img  src={quizME} style={{width:'11%',height:'11%',marginLeft:'10px'}} onClick={(e)=>showGame()}></img>
      </Grid>
      <Divider />


      
      <List style={{maxHeight:'350px',minHeight:'350px',overflow:'auto'}}>
        {historyData && historyData.map((row,index)=>
        (
            <ListItem button key={index} style={{borderBottom:'1px solid lightgrey'}} >
            <ListItemText id="history" primary={row.question} onClick={(e)=>storeHisory(row)}/>
            <Tooltip title="Delete Chat">
            <DeleteIcon onClick={(e)=>deleteHistory(e,row)}></DeleteIcon>
            </Tooltip>
          </ListItem>
        ))}
      </List>


      <Typography style={{borderBottom:'1px solid lightgrey',padding:'0px 0px 0px 10px',fontWeight:'bold'}}>Most Popular</Typography>
      <List style={{maxHeight:'180px',minHeight:'180px',overflow:'auto'}}>
      {frequentlyaskedQuestion && frequentlyaskedQuestion.map((row,index)=>
        (
            <ListItem button key={index} style={{borderBottom:'1px solid lightgrey'}} >
            <ListItemText id="history" primary={row.question} onClick={(e)=>call_data(row.question)}/>
          </ListItem>
        ))}
      </List>
      <Divider />
      
      <div style={{ flexGrow: 1 }} />
      {/* <Typography style={{fontWeight:'bolder',paddingLeft:'10%'}}>Powered By </Typography> */}
      {/* <h6>Powered By</h6> */}
      <ListItem button >
        
      <img src={AsoftLogo} style={{width:'50%',height:'10%'}}></img>
      </ListItem>
           {/* <AccountCircle></AccountCircle> 
           <ListItemText style={{paddingLeft:'10px'}} primary="" />
           
          </ListItem> */}
          <ListItem button >
          <ExitToAppIcon></ExitToAppIcon>
          <ListItemText style={{paddingLeft:'10px'}}       onClick={handleLogout}
 primary="Logout" />
           </ListItem>
      <Divider />
      
    </div>
  );
  const handleCopyToClipboard = (row) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        // If navigator.clipboard.writeText() is available, use it
        navigator.clipboard.writeText(row.answer)
            .then(() => {
               
            })
            .catch(error => {
                console.error('Error copying text to clipboard:', error);
            });
    } else {
        // Fallback method for non-supporting browsers
        const textArea = document.createElement('textarea');
        textArea.value = row.answer;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        
    }
};

  const [showsuggestionupdates,setshowsuggestionupdates]=useState(true)
  const container = window !== undefined ? () => window().document.body : undefined;
  const [weeklyupdates,setweeklyupdates]=useState([])
  const [futureupdates,setfutureupdates]=useState([])
  const [suggestionupdates,setsuggestionupdates]=useState([])
  

const [disclaimerupdates,setdisclaimerupdates]=useState([{'key':'General Information:','updates':' Botzilla is an automated system designed to provide information and assistance based on information requests. While we strive to provide accurate information through Botzilla, we cannot guarantee the accuracy, completeness, or up-to-date nature of the information provided. We recommend that you use responses as a starting point and verify the information through the public documentation sources.'},
  {'key':'User Responsibility:','updates':" Users of Botzilla bear sole responsibility for their interactions and reliance on the information provided. It is important to exercise caution and use your discretion while interpreting and acting upon the Botzilla's responses. We cannot be held liable for any actions, losses, or damages resulting from the use of Botzilla."},
  {'key':'Data Privacy and Security:','updates':" We prioritize the privacy and security of our users' information. Be aware that conversations may be reviewed by our content team to improve results. While we retain logs of these conversations for 30 days, this data is not used to train their models. Do not share any sensitive or personal information in your conversations."}
])


  return (
    <div className={classes.root}>

<Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        key="weekly important"

      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Upcoming Weekly Important Updates"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {weeklyupdates && weeklyupdates.map((row,index)=>
          (
            <li key={index} style={{padding:'10px'}}>{row.updates}</li>
          ))}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>


      <Dialog
        open={Disclaimer}
        keepMounted
        onClose={handleCloseDisclaimer}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        key="terms and conditions"
      >
        <DialogTitle key="terms and conditions title" id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px',textAlign:'center'}}>{"Terms and Policies"}</DialogTitle>
        <DialogContent key="terms and conditions content">
          <DialogContentText id="alert-dialog-slide-description" key="terms and conditions contenttext">
            <Typography style={{padding:'5px',fontSize:'14px'}} key="terms and conditions typo">The following disclaimer is intended to inform users of the limitations and usage guidelines of the Botzilla. Please read this disclaimer carefully before using it.</Typography>
          {disclaimerupdates && disclaimerupdates.map((row,index)=>
          (
            <p key={index} style={{padding:'5px',fontSize:'14px'}}><b>{index+1 +"."}</b>{row.updates}</p>
          ))}
            <Typography style={{padding:'5px',fontSize:'14px'}}>By using our Botzilla, you indicate your acceptance and understanding of the above disclaimer. If you do not agree with any part of this disclaimer, we recommend refraining from using Botzilla. For further assistance or inquiries, please contact Support at <u style={{cursor:'pointer'}}><b>info@asoftglobal.com.</b></u></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Button onClick={handleCloseDisclaimer} variant="contained" color="primary">
            OK
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>


      <Dialog
        open={openfuture}
        keepMounted
        onClose={handleClosefuture}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        key="upcoming important"

      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Upcoming Future Important Updates"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {futureupdates && futureupdates.map((row,index)=>
          (
            <li key={index} style={{padding:'10px'}}>{row.updates}</li>
          ))}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Button onClick={handleClosefuture} variant="contained" color="primary">
            OK
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>



            
      <Dialog
        open={opengame}
        keepMounted
        onClose={handleClosegame}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        key="quiz"

      >


        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey'}}>{"Please answer some quizz to get started"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {quizquestion && quizquestion.map((row,index)=>
          (
            <Grid key={index} item container lg={12} sm={12} md={12} style={{display:'flex'}}>
              <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
              <Typography  style={{padding:'10px'}}>{row && row.question}</Typography>
  </Grid>
            
            
  <Grid item container lg={6} md={6} sm={6} style={{padding:'10px',display:'grid'}}>
                  <Button variant="contained" style={{ backgroundColor: (buttonshow===row.answer1?bgcolor:'')}} onClick={(e)=>showcolor(row.answer1,row.correct_answer)}>
              {row && row.answer1}
            </Button>
            </Grid>
            <Grid item container lg={6} md={6} sm={6} style={{padding:'10px',display:'grid'}}>
                  <Button variant="contained" style={{ backgroundColor: (buttonshow===row.answer2?bgcolor:'')}} onClick={(e)=>showcolor(row.answer2,row.correct_answer)}>
              {row && row.answer2}
            </Button>
            </Grid>

            <Grid item container lg={6} md={6} sm={6} style={{padding:'10px',display:'grid'}}>
                  <Button variant="contained" style={{ backgroundColor: (buttonshow===row.answer3?bgcolor:'')}} onClick={(e)=>showcolor(row.answer3,row.correct_answer)}>
              {row && row.answer3}
            </Button>
            </Grid>

            <Grid item container lg={6} md={6} sm={6} style={{padding:'10px',display:'grid'}}>
                  <Button variant="contained" style={{ backgroundColor: (buttonshow===row.answer4?bgcolor:'')}} onClick={(e)=>showcolor(row.answer4,row.correct_answer)}>
              {row && row.answer4}
            </Button>
            </Grid>

            </Grid>
          ))}
          
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'2px solid lightgrey'}}>
         <Grid item container lg={5} sm={5} md={5} >
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7} style={{paddingTop:'10px'}}>
          <Button onClick={handleClosegame} variant="contained" color="primary">
            Close
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>


      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
        {/* <p style={{display:'contents'}}><img src={Botzilla} style={{width:'4%'}}></img></p>  */}
          <Typography variant="h6" noWrap style={{paddingLeft:'10px',display:'contents'}}>
         {/* BOTZILLA */}
         <img src={Botzilla} style={{width:'10%',height:'20%'}}></img>

          </Typography>
         
          {/* <img src={Botzilla} style={{width:'5%',height:'20%',marginLeft:'85%'}}></img> */}
         
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, 
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main style={{paddingTop:'3.8%'}}>

     
      <p style={{float:'left',textDecoration:'underline',color:'#3a3acb',cursor:'pointer',paddingLeft:'10px'}} onClick={handleClickOpenfuture}>Future Updates</p>
        <p style={{float:'right',textDecoration:'underline',color:'#3a3acb',cursor:'pointer',paddingRight:'10px'}} onClick={handleClickOpen}>Weekly Updates</p>
        <Grid item container lg={12} sm={12} md={12} style={{display:'flex',backgroundColor:'white'}}>
        <Grid item container lg={1} sm={1} md={1} >
        {/* <img src={Botzilla} style={{width:'100%',padding:'10px'}}></img> */}
                </Grid>
                <Grid item container  ref={containerRef}  lg={7} sm={7} md={7} style={{minHeight:'480px',maxHeight:'480px',overflow:'auto',border:'1px solid lightgrey',borderRadius:'0px 0px 0px 0px',display:'block'
              }}>


                    
                    
                
                {showsuggestionupdates && 

                <Grid item container lg={12} sm={12} md={12} style={{display:'flex',marginTop:'0%'}}>
                <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
                <Grid item container lg={3} sm={3} md={3}></Grid>
                      <Grid item container lg={6} sm={6} md={6} style={{display:'inline-table'}}>

                      <img src={Botzilla} style={{width:'105%',height:'10%'}}></img>
                      </Grid>
                       
                      <Grid item container lg={5} sm={5} md={5}></Grid>
                      <Grid item container lg={6} sm={6} md={6} style={{paddingBottom:'10px',display:'block',fontSize:'14px',fontWeight:'bold'}}>How can I help you today?</Grid>
                       
                      </Grid>
                  {/* {suggestionupdates && suggestionupdates.map((row,index)=>
                  (
                    <Grid key={index} item container lg={6} sm={6} md={6} style={{padding:'20px',display:'block',cursor:'pointer'}} onClick={(e)=>calluserdetails(e,row)}>

          <Card >
               
                  
                  <CardContent style={{padding:'2px'}}>
                  <Typography variant="body2" color="textSecondary" component="p" style={{textAlign:'center',fontWeight:'bolder'}}>
                    {row.key_details}
                    </Typography>
                    
                    <Typography variant="body2" color="textSecondary" component="p" style={{opacity:'0.8',textAlign:'center',paddingTop:'10px'}}>
                    {row.updates}
                    </Typography>
                  </CardContent>
                
              </Card>

                      </Grid>
                  ))} */}
                </Grid>
                }
                {chatgptresponse && chatgptresponse.map((row,index)=>
                (
                    <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}} key={index}>
                <Grid item container lg={1} sm={1} md={1} style={{display:'block'}}><p style={{paddingLeft:'15px'}}><img src={UserQueryImage} style={{width:'61%',height:'1%'}}></img></p></Grid>

                <Grid item container lg={10} sm={10} md={10} style={{padding:'10px',display:'block'}}  onMouseEnter={(e)=>setediticonindexquestion(index,row.question)}>
                                        {/* <AccountCircleIcon></AccountCircleIcon>     */}


                                      {index===editiconindex &&   <Tooltip title="Edit Question"><EditIcon fontSize='small' onClick={(e)=>sethoverindexQuestion(index)} style={{cursor:'pointer'}} ></EditIcon></Tooltip>}                                        

                                        {index===hoverindexQuestion ?
                                        
                                        <TextField value={row.question} style={{display:'flex'}} onChange={(e)=>setnewanswer(e,row.question,index)} 
                                        multiline
                                        onKeyDown={(e)=>handleKeyPressEdit(e,index,row.question)}

                                        // ></TextField>:<Typography style={{paddingTop:'2px',backgroundColor:'lightgrey',padding:'10px',borderRadius:'10px',fontSize:'13px'}} >{displayText(row.question)}</Typography>
                                        ></TextField>:<Typography style={{paddingTop:'2px',backgroundColor:'lightgrey',padding:'10px',borderRadius:'10px',fontSize:'13px'}} >{displayText(row.question)}</Typography>

                                        } 
                
                
                </Grid>
                <Grid item container lg={1} sm={1} md={1}></Grid>

                <Grid item container lg={1} sm={1} md={1} style={{ display: 'block', marginTop: '-5px', paddingLeft: '1%' }}>
  <p style={{ padding: '1px' }}>
    <img src={Botzilla1} style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'fill' ,border:'0.1px solid #f2efef'}} alt="Chatbot" />
  </p>
</Grid>              
               <Grid item container lg={10} sm={10} md={10} style={{padding:'10px',display:'block'} }
              // onClick={() => {navigator.clipboard.writeText(row.answer)}}
              onClick={(e)=>handleCopyToClipboard(row)}
              onMouseEnter={(e)=>sethoverindexresponse(index)}
               >
                    {/* <AccessibilityIcon></AccessibilityIcon> */}
                <Typography id="response" style={{paddingTop:'2px',backgroundColor:'lightblue',padding:'10px',borderRadius:'10px',whiteSpace:'pre-line'}}
                 
                >

                              

                 {index===hoverindex && <>
                 
                 
                  <Tooltip title="">
                  <div>
                   <MoreVertIcon  aria-describedby={id1} onClick={handleClick1} fontSize='small' style={{cursor:'pointer',float:'inline-end'}}></MoreVertIcon> 
       <Popover
        id={id1}
        open={open2}
        anchorEl={anchorE2}
        onClose={handleCloseAnchor1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography style={{paddingLeft:'25px',paddingRight:'25px',paddingTop:'10px',minWidth:'140px',cursor:'pointer'}} onClick={(e)=>translateData(index,"Telugu",row.answer,row.question)}>Telugu</Typography>
        <Typography style={{paddingLeft:'25px',paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px',minWidth:'140px',cursor:'pointer'}} onClick={(e)=>translateData(index,"Hindi",row.answer,row.question)}>Hindi</Typography>
      </Popover> 
    </div>
    </Tooltip>


                 <Tooltip title="Copy Response"><FileCopyIcon fontSize='small' style={{cursor:'pointer'}}></FileCopyIcon></Tooltip></> } 
                    {/* { ((chatgptresponse.length-1)===index && provideAnimation) ?<TypeAnimation
            sequence={[row.answer,100]}
            // repeat={Infinity}
            style={{ fontSize: '1em', display: 'grid' ,whiteSpace: 'pre-wrap',wordWrap:'break-word'}}
            wrapper="div"
            speed={50, {type: "keyStrokeDelayInMs", value: 3,cursor:false}}
            /> :<Typography style={{paddingTop:'1px',fontSize:'13px'}} >{displayText(row.answer)}</Typography>} */}

<Typography style={{paddingTop:'1px',fontSize:'13px'}} >{displayText(row.answer)}</Typography>
                    </Typography>
                    
                    
                    {(row.answer_response==null || row.answer_response=="null" || row.answer_response=='' || row.answer_response!="1") ? (<ThumbUpAltOutlinedIcon style={{margin:'10px',cursor:'pointer'}} onClick={(e)=>updateLike(e,row,"1")} 
                      className={clsx({
                        [classes.opacity]:(row.answer_response!="1" && row.answer_response!=null && row.answer_response!="null")
                      })}

                      
                      />  ):(<ThumbUpAltRoundedIcon style={{margin:'10px',cursor:'pointer'}} onClick={(e)=>updateLike(e,row,null)} 
                      className={clsx({
                        [classes.opacity]:(row.answer_response!="1" && row.answer_response!=null && row.answer_response!="null")
                      })}
                      />)} 
                     
                    {((row.answer_response==null || row.answer_response=="null" || row.answer_response=='' || row.answer_response=="1") )  ?(<ThumbDownAltOutlinedIcon style={{margin:'10px 10px 10px 0px',cursor:'pointer'}}
                    className={clsx({
                      [classes.opacity]:(row.answer_response=="1" && row.answer_response!=null && row.answer_response!="null")
                    })}
                    onClick={(e)=>updateDisLike(e,row,"0")}   />):(<ThumbDownRoundedIcon style={{margin:'10px 10px 10px 0px',cursor:'pointer'}} onClick={(e)=>updateDisLike(e,row,null)} 
                    className={clsx({
                      [classes.opacity]:(row.answer_response=="1" && row.answer_response!=null && row.answer_response!="null")
                    })}
                    />) }
                </Grid>

                <Grid item container lg={1} sm={1} md={1}></Grid>

                    </Grid>
                )
                )}
                </Grid>
                {/* <Grid item container lg={1} sm={1} md={1} >
                
                </Grid> */}

                <Grid item container lg={4} sm={4} md={4} style={{padding:'10px 10px 10px 30px',maxHeight:'200px',display:'flow'}} >
                {/* <img src={Botzilla} style={{width:'100%',padding:'10px'}}></img> */}
                <Card >
      <CardActionArea style={{
        backgroundImage: `url(${firstadvertisment1})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: '100%',
        backgroundRepeat:'inherit',
        height: '210px', // Adjust height as needed
        // You can add more background properties here
      }}
      >
        
        <CardContent>
       
          {/* <img src={Advertisment1}></img> */}
          <Typography variant="body2" color="textSecondary" component="p" style={{opacity:'0.01'}}>
          BEC was established in 1981. The founder president for this institute is Yarlagadda Krishna Murthy. It is located in Bapatla district.
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
       
      </CardActions> */}
    </Card>

    <Card style={{marginTop:'10px'}}>
      <CardActionArea style={{
        backgroundImage: `url(${firstadvertisment2})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat:'inherit',

        width: '100%',
        height: '220px', // Adjust height as needed
        // You can add more background properties here
      }}>
        
        <CardContent>
       
          {/* <img src={Advertisment1}></img> */}
          <Typography variant="body2" color="textSecondary" component="p" style={{opacity:'0.01'}}>
          BEC was established in 1981. The founder president for this institute is Yarlagadda Krishna Murthy. It is located in Bapatla district.
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
       
      </CardActions> */}
    </Card>

    {/* <Typography style={{padding:'10px',fontSize:'14px'}} className='blink'>Disclaimer:  We strive to provide accurate information through Botzilla, we cannot guarantee the accuracy, completeness, or up-to-date nature of the information provided</Typography> */}
                </Grid>
                <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
        <Grid item container lg={1} sm={1} md={1} >
                </Grid>
                <Grid item container lg={7} sm={7} md={7} style={{display:'grid'}}>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" style={{paddingTop:'10px'}}>
          <OutlinedInput
            id="outlined-adornment-weight"
            // multiline
            spellCheck="true"
            value={userQuery}
          maxRows={2}
          onChange={(e)=>handleTextChange(e)}
          onKeyDown={(e)=>handleKeyPress(e)}
          endAdornment={
            <>
             <Button onClick={handleListen} color="primary">
              {isListening ? <Mic /> : <MicOff />}
            </Button>
            
              {/* <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={callApi}>
                Submit
              </InputAdornment> */}
            </>
          }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
          />
        </FormControl>
                </Grid>

                <Grid item container lg={4} sm={4} md={4} >
                </Grid>

                <Grid item container lg={1} sm={1} md={1} >
                </Grid>

                <Grid item container lg={10} sm={10} md={10} style={{fontSize:'15px',opacity:'0.5',lineHeight:'0'}}>
                  <p style={{display:'flex',paddingLeft:'10px'}}>Version.0.1.1 - Botzilla is not responsible for the accuracy of information provided. </p>
                  <p style={{textDecoration:'underline',fontWeight:'bold',cursor:'pointer'}} onClick={(e)=>setDisclaimer(true)}> Read Terms and Policies</p>
                </Grid>
                <Grid item container lg={3} sm={3} md={3} >
                </Grid>

               
                </Grid>
        </Grid>
      </main>
    </div>
  );
}
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};
export default ResponsiveDrawer;
