import { React, useState, useEffect, useCallback  } from 'react'
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';
import Spinner from './Spinner';
import FaceIcon from '@material-ui/icons/Face';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import AsoftLogo from './logo2.png'
import WcIcon from '@material-ui/icons/Wc';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Botzilla from './BotzillaNew1.jpeg'
import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LockIcon from '@material-ui/icons/Lock';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PublicIcon from '@material-ui/icons/Public';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  registerpadding:
  {
    margin:'8px'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  formControl:
  {
    padding:'9px 0px 0px 12px',
    minWidth:'43%'
  },
  formControl1:
  {
    padding:'9px 10px 0px 12px',
    minWidth:'43%'
  },

  paddingtopregister:
  {
    paddingTop:'5%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },

  opacity:
  {
    pointerEvents:'none',
    opacity:'0.4'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));


function ForgotPasswordPage(props) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [username,setusername]=useState('')
  const [password,setpassword]=useState('')
  const [passwordregister,setpasswordregister]=useState('')
  const [showregister,setshowregister]=useState(false)
  const [open, setOpen] = useState(false);
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const [filesdata,setfilesdata]=useState("")
  const [advertisment,setadvertisment]=useState('')
  const [loader,setLoader]=useState(false)
  const classes = useStyles();
  const [successopenmsg,setsuccessopenmsg]=useState('')
  const navigate = useNavigate();


  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [isalphanumeric,setisalphanumeric]=useState(false)

  function setpasswordvalidation(e)
  {
    setpassword(e)
    if (passwordPattern.test(e))
      {
        setisalphanumeric(true)
      }
      else{
        setisalphanumeric(false)
      }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      validateUser(e);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;

    // Allow only digits and ensure the length is exactly 10
      setphonenumber(newValue);
    
  };
  

  
  const handleChangeSate = (e) => {
    
    setstate(e.target.value)
};

  const handleChangeGender = (e) => {
    
      setgender(e.target.value)
  };

  const [firstname,setfirstname]=useState('')
  const [lastname,setlastname]=useState('')
  const [studentId,setstudentId]=useState('')
  const [emailaddress,setemailaddress]=useState('')
  const [phonenumber,setphonenumber]=useState('')
  const [gender,setgender]=useState('')
  const [address,setaddress]=useState('')
  const [address1,setaddress1]=useState('')

  const [city,setcity]=useState('')

  const [state,setstate]=useState('')

  const [zip,setzip]=useState('')


  const [forgotpassword,setforgotpassword]=useState(false)
  const [validateOtpshow,setvalidateOtpshow]=useState(false)

  const [showpassword,setshowpassword]=useState('')

function updateUserPassword()
{
  

  setsuccessmsg('')
  seterrormsg('')
  setLoader(true)

  let data={"username":username,'password':password}
    axiosService.post("updatepassword",data).then(
      response => 
      { 
          setLoader(false)
          if (response.data.status=="success")
            {
              
              setsuccessmsg("Password Updated successfully.")
              setshowpassword(false)
              setvalidateOtpshow(false)
              setusername('')
              setuserOtp('')
              setisalphanumeric(false)

            }
            else
            {
              seterrormsg(response.data.msg)
            }
      }
      )
      .catch(error => 
          {
              console.log("error")
              // setfilesdata("")
          // setadvertisment("")
          setLoader(false)
          seterrormsg("Something went wrong")
          }
      )

}

  function validateUserOtp(e)
  {

    setsuccessmsg('')
    seterrormsg('')
    setLoader(true)

    let data={"username":username,'userOtp':userOtp}
      axiosService.post("validateotp",data).then(
        response => 
        { 
            setLoader(false)
            if (response.data.status=="success")
              {
                
                setsuccessmsg("OTP validated successfully.")
                setshowpassword(true)
              }
              else
              {
                seterrormsg(response.data.msg)
              }
        }
        )
        .catch(error => 
            {
                console.log("error")
                // setfilesdata("")
            // setadvertisment("")
            setLoader(false)
            seterrormsg("Something went wrong")
            }
        )

  }

  function validateOtp(e)
  {
    setvalidateOtpshow(false)
    setsuccessmsg('')
    seterrormsg('')
    setLoader(true)
      let data={"username":username}
      axiosService.post("generateotp",data).then(
        response => 
        { 
            setLoader(false)
            if (response.data.status=="success")
              {
                
                setsuccessmsg("OTP sent to your registered email address.")
                setvalidateOtpshow(true)
              }
              else
              {
                seterrormsg(response.data.msg)
              }
        }
        )
        .catch(error => 
            {
                console.log("error")
                // setfilesdata("")
            // setadvertisment("")
            setLoader(false)
            seterrormsg("Something went wrong")
            }
        )


  }

  function validateUser(e)
  {
    
    setsuccessmsg('')
    seterrormsg('')
    const emailPattern = /^[^\s@]+@[^\s@]+\.(com|in)$/;
    if (!emailPattern.test(username)) {
      seterrormsg('Email address is not valid.');
      
    }
    else
    {
      setsuccessmsg('')
    seterrormsg('')
    setLoader(true)
      let data={"username":username}
      axiosService.post("passwordchange",data).then(
        response => 
        { 
            setLoader(false)
            if (response.data.status=="success")
              {
                
                setsuccessmsg("Password sent to your registered email address.")
              }
              else
              {
                seterrormsg(response.data.msg)
              }
        }
        )
        .catch(error => 
            {
                console.log("error")
                // setfilesdata("")
            // setadvertisment("")
            setLoader(false)
            seterrormsg("Something went wrong")
            }
        )
    }

    
  }

  const [errormsg,seterrormsg]=useState('')
  const [successmsg,setsuccessmsg]=useState('')
  function validateUserRegister(e) {
  
    let status=true
    setsuccessmsg('')
    seterrormsg('')
    // Validation for first name and last name
    if (firstname.length < 5) {
      seterrormsg('First name should be at least 5 characters long.');
      status=false
    }
    if (lastname.length < 5) {
      seterrormsg('Last name should be at least 5 characters long.');
      status=false
    }
  
    // Validation for student ID
    if (studentId.length < 5) {
      seterrormsg('Student ID should be at least 5 characters long.');
      status=false
    }
  
    // Validation for email
    const emailPattern = /^[^\s@]+@[^\s@]+\.(com|in)$/;
    if (!emailPattern.test(emailaddress)) {
      seterrormsg('Email address is not valid.');
      status=false
    }
  
    // Validation for phone number
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(phonenumber)) {
      seterrormsg('Phone number should be exactly 10 digits long.');
      status=false
    }
  
    // Validation for gender
    if (gender === '') {
      seterrormsg('Gender should not be null.');
      status=false
    }
  
    // Validation for address
    if (address.length < 5) {
      seterrormsg('Address should be at least 5 characters long.');
      status=false
    }
    if (address1.length < 5) {
      seterrormsg('Address1 should be at least 5 characters long.');
      status=false
    }
  
    // Validation for city, state, and zip
    if (city.length < 5) {
      seterrormsg('City should be at least 5 characters long.');
      status=false
    }
    if (state.length < 5) {
      seterrormsg('State should be at least 5 characters long.');
      status=false
    }
    if (zip.length < 5) {
      seterrormsg('Zip should be at least 5 characters long.');
      status=false
    }
  
    if (status)
      {
        console.log("Inside")
        setLoader(true)
        let data={'firstname':firstname,
              'lastname':lastname,
              'studentId':studentId,
              'emailaddress':emailaddress,
              'phonenumber':phonenumber,
              'gender':gender,
              'address':address,
              'address1':address1,
              'city':city,
              'state':state,
              'zip':zip

        }
        axiosService.post("register",data).then(
          response => 
          { 
              setLoader(false)
              if (response.data.status=="success")
                {
                  seterrormsg('')
                  setsuccessmsg(response.data.msg)
                  resetregistervalues()
                  localStorage.setItem("emailId",emailaddress)
                }
                else
                {
                  seterrormsg(response.data.msg)
                }
          }
          )
          .catch(error => 
              {
                  console.log("error")
              setLoader(false)
            
              seterrormsg("Something went wrong")
              }
          )
      }
  }

  const [isemailvalid,setisemailvalid]=useState(true)

  function checkEmail(e)
  {
      setusername(e.target.value)
      const emailPattern = /^[^\s@]+@[^\s@]+\.(com|in)$/;
      if (!emailPattern.test(e.target.value)) {
        setisemailvalid(true)
      
    }
    else{
      setisemailvalid(false)
    }
    
  }

  const [userOtp,setuserOtp]=useState('')
  
  function resetregistervalues()
  {
    setfirstname('')
    setlastname('')
    setstudentId('')
    setemailaddress('')
    setaddress('')
    setaddress1('')
    setzip('')
    setstate('')
    setgender('')
    setphonenumber('')
    setcity('')
  }
  return (
   
<Card className={classes.root}>
{loader && <Spinner></Spinner>}
      <CardContent style={{display:'inline-flex',minHeight:'600px',margin:'5px'}}>
        <div style={{background:'linear-gradient(180deg, #ff0000 0%, #ff0000 100%)',width:'200px',height:'200px',position:'absolute',top:'-5rem',left:'-6.5rem',borderRadius:'50%'}}></div>
        <img src={Botzilla} style={{width:'45%',height:'50%',paddingTop:'5%',paddingLeft:'5%'}}></img>
        
        <Grid item container lg={12} sm={12} md={12} style={{paddingLeft:'15%',display:'block'}}
        className={clsx({
          [classes.paddingtopregister]:!showregister
        })}
        >
        <Typography variant="body2" color="textSecondary" component="p" style={{fontWeight:'900',fontSize:'25px',display:'flex'}}>
        Welcome to <Typography variant="body2" color="textSecondary" component="p" style={{fontWeight:'900',fontSize:'25px',color:'red',paddingLeft:'10px'}}> BOTZILLA</Typography>
        </Typography>
        
        {showregister ?(<>
        
          <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="First Name"
        value={firstname}
        onChange={(e)=>setfirstname(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Last Name"
        value={lastname}
        onChange={(e)=>setlastname(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaceIcon />
            </InputAdornment>
          ),
        }}
      />

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Studen ID"
        value={studentId}
        onChange={(e)=>setstudentId(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CardMembershipIcon />
            </InputAdornment>
          ),
        }}
      />

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Email Address"
        value={emailaddress}
        onChange={(e)=>setemailaddress(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />



<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Phone Number"
        value={phonenumber}
        type='number'
        onChange={handleChange}
        InputProps={{
          maxLength: 10, // HTML5 attribute for input length limit

          startAdornment: (
            <InputAdornment position="start">
              <PhoneIcon />
            </InputAdornment>
          ),
        }}
      />


<FormControl className={classes.formControl}>
      <InputLabel htmlFor="gender-native-simple" style={{ paddingLeft: '10px' }}>Gender</InputLabel>
      <Select
        native
        value={gender}
        onChange={handleChangeGender}
        input={
          <Input
            id="gender-native-simple"
            startAdornment={
              <InputAdornment position="start">
                <WcIcon />
              </InputAdornment>
            }
          />
        }
      >
        <option aria-label="None" value="" />
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </Select>
    </FormControl>

{/* <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Gender"
        value={gender}
        onChange={(e)=>setgender(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WcIcon />
            </InputAdornment>
          ),
        }}
      /> */}

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Address No 1"
        value={address}
        onChange={(e)=>setaddress(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HomeIcon />
            </InputAdornment>
          ),
        }}
      />


<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="City"
        value={city}
        onChange={(e)=>setcity(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationCityIcon />
            </InputAdornment>
          ),
        }}
      />

{/* <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="State"
        value={state}
        onChange={(e)=>setstate(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PublicIcon />
            </InputAdornment>
          ),
        }}
      /> */}

<FormControl className={classes.formControl1}>
      <InputLabel htmlFor="gender-native-simple" style={{ paddingLeft: '10px' }}>State</InputLabel>
      <Select
        native
        value={state}
        onChange={handleChangeSate}
        input={
          <Input
            id="gender-native-simple"
            startAdornment={
              <InputAdornment position="start">
                <PublicIcon />
              </InputAdornment>
            }
          />
        }
      >
        <option aria-label="None" value="" />
        <option value="Andhra Pradesh">Andhra Pradesh</option>
        <option value="Hyderabad">Hyderabad</option>
      </Select>
    </FormControl>


<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Zip"
        value={zip}
        type='number'
        onChange={(e)=>setzip(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography style={{display:'grid'}}>

      <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Address No 2"
        value={address1}
        onChange={(e)=>setaddress1(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HomeIcon />
            </InputAdornment>
          ),
        }}
      />


      {/* <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Password"
        type="password"
        value={passwordregister}
        onChange={(e)=>setpasswordregister(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      /> */}

      </Typography>

<Grid item container lg={12} sm={12} md={12} style={{paddingTop:'2%',display:'grid'}}>
            <Button variant="contained" color="primary"
            
            className={clsx(classes.margin,{
              [classes.opacity]:( firstname=='' || lastname=='' || studentId=='' || emailaddress=='' || phonenumber=='' || gender=='' || address=='' || city=='' || state=='' || zip=='' || address1=='')
            })}
            onClick={(e)=>validateUserRegister(e)}
            >
        Register
      </Button>
</Grid>
{console.log("errormsg11",errormsg)}


<Typography style={{paddingTop:'10px',display:'grid',float:'left',paddingLeft:'55%',textDecoration:'underline',color:'blue',cursor:'pointer'}} onClick={(e)=>setshowregister(false)}>
        Already Registered ? Login
      </Typography>


        </>):(<>
        <Typography style={{paddingTop:'20px',display:'grid'}}>
        <TextField
        id="input-with-icon-textfield"
        label="Email Address"
        value={username}
        className={clsx(classes.margin,{
          [classes.opacity]:( showpassword)
        })}
        onChange={(e)=>checkEmail(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
      </Typography>

      



      

     <span style={{textDecoration:'underline',color:'blue',marginTop:'10px',cursor:'pointer'}}
      className={clsx({
        [classes.opacity]:( username=='' || isemailvalid==true || showpassword)
      })}
      onClick={(e)=>validateOtp(e)}
      >
        Get OTP
      </span>



{(validateOtpshow && !isemailvalid) && <>
<Typography style={{paddingTop:'10px',display:'grid'}}>
        <TextField
        id="input-with-icon-textfield"
        label="Enter Otp"
        type="password"
        value={userOtp}
        onChange={(e)=>setuserOtp(e.target.value)}
        className={clsx(classes.margin,{
          [classes.opacity]:( showpassword)
        })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      </Typography>
      <span style={{textDecoration:'underline',color:'blue',marginTop:'10px',cursor:'pointer'}}
      className={clsx(classes.margin,{
        [classes.opacity]:( showpassword)
      })}
      onClick={(e)=>validateUserOtp(e)}
      >
        Validate Otp
      </span>


      </>
      }


{showpassword && <Typography style={{paddingTop:'10px',display:'grid'}}>
        <TextField
        className={classes.margin}
        id="input-with-icon-textfield"
        label="Create Password"
        type="password"
        value={password}
        onChange={(e)=>setpasswordvalidation(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      </Typography>}

      
      <p style={{paddingTop:'5px'}}>Note : Your password must be at least 8 characters long and include at least one letter, one digit, and one special character from @$!%*?&.</p>

        
      <Grid item container lg={12} sm={12} md={12} style={{paddingTop:'5%',display:'grid'}}>
            <Button variant="contained" color="primary"
            
            className={clsx(classes.margin,{
              [classes.opacity]:( !isalphanumeric)
            })}
            onClick={(e)=>updateUserPassword(e)}
            >
        Create Password
      </Button>
   <Link to="/login">  <Typography style={{paddingTop:'10px',display:'grid',float:'left',paddingLeft:'55%',textDecoration:'underline',color:'blue',cursor:'pointer'}} >
   Already Registered ? Login
      </Typography></Link> 
      {errormsg && <Typography style={{paddingTop:'0px',color:'red'}}>{errormsg}</Typography>}

{successmsg && <Typography style={{paddingTop:'0px',color:'green'}}>{successmsg}</Typography>}

      </Grid></>) }
        
       <Grid item container lg={12} sm={12} md={12} style={{paddingTop:'5%',paddingLeft:'50%'}}>
              <Typography style={{paddingTop:'9.5%',paddingRight:'5%'}}>Powered by</Typography>
              <img src={AsoftLogo} style={{width:'50%',height:'10%'}}></img>
        </Grid>

              </Grid>
             
        </CardContent>
        {/* <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
        <Grid item container lg={10} sm={10} md={10} >
              
              </Grid>
              <Grid item container lg={2} sm={2} md={2} >
<div style={{
  background: 'linear-gradient(180deg, #000 0%, #000 100%)',
  width: '200px',
  height: '200px',
  bottom: '-4rem',
  right: '-5.5rem',
  transform: "rotate(180deg)",
  position: 'absolute',
  borderRadius: '50%',
  clipPath: 'inset(0 0 0 50%)'
}}></div>
              </Grid>
        </Grid> */}
    </Card>

  );
}
ForgotPasswordPage.propTypes = {
  window: PropTypes.func,
};
export default ForgotPasswordPage;
