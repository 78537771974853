import { React, useState, useEffect, useCallback  } from 'react'
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';

import Spinner from './Spinner';
import FaceIcon from '@material-ui/icons/Face';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import AsoftLogo from './logo2.png'
import WcIcon from '@material-ui/icons/Wc';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Botzilla from './BotzillaNew1.jpeg'
import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LockIcon from '@material-ui/icons/Lock';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PublicIcon from '@material-ui/icons/Public';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  registerpadding:
  {
    margin:'8px'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  formControl:
  {
    padding:'9px 0px 0px 12px',
    minWidth:'43%'
  },
  formControl1:
  {
    padding:'9px 10px 0px 12px',
    minWidth:'43%'
  },

  paddingtopregister:
  {
    paddingTop:'5%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },
  red:
  {
    color:'red'
  },
  green:
  {
    color:'green'
  },

  opacity:
  {
    pointerEvents:'none',
    opacity:'0.4'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));


function PaswwordPage(props) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [username,setusername]=useState('')
  const [password,setpassword]=useState('')
  const [passwordregister,setpasswordregister]=useState('')
  const [showregister,setshowregister]=useState(false)
  const [open, setOpen] = useState(false);
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const [filesdata,setfilesdata]=useState("")
  const [advertisment,setadvertisment]=useState('')
  const [loader,setLoader]=useState(false)
  const classes = useStyles();
  const [successopenmsg,setsuccessopenmsg]=useState('')
  const navigate = useNavigate();


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      validateUser(e);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;

    // Allow only digits and ensure the length is exactly 10
      setphonenumber(newValue);
    
  };
  


  
  const handleChangeSate = (e) => {
    
    setstate(e.target.value)
};

  const handleChangeGender = (e) => {
    
      setgender(e.target.value)
  };

  const [firstname,setfirstname]=useState('')
  const [lastname,setlastname]=useState('')
  const [studentId,setstudentId]=useState('')
  const [emailaddress,setemailaddress]=useState('')
  const [phonenumber,setphonenumber]=useState('')
  const [gender,setgender]=useState('')
  const [address,setaddress]=useState('')
  const [address1,setaddress1]=useState('')

  const [city,setcity]=useState('')

  const [state,setstate]=useState('')

  const [zip,setzip]=useState('')


  const [confirmpassword,setconfirmpassword]=useState('')
  
  function validateUser(e)
  {
    
    if (password==confirmpassword)
      {
        setLoader(true)
        let data={"emailadress":localStorage.getItem("emailId"),"password":password}
        axiosService.post("createpassword",data).then(
          response => 
          { 
              setLoader(false)
              console.log("response.data.status",response.data.status)
              if (response.data.status=="success")
                {

                  setsuccessopenmsg(response.data.msg)
                  setpassword('')
                  setconfirmpassword('')
                  setisalphanumeric(false)
                  setisalphanumericonfirm(false)
                  if (response.data.msg=="Password Created Successfully. You will be automatically redirected to Login Screen in 5 seconds")
                    {
                      setTimeout(() => {
                        navigate("/login");
                      }, 5000);
                    }
                 
                }
                else
                {
                  setsuccessopenmsg("Something went wrong")
                }
              
              
          }
          )
          .catch(error => 
              {
                  console.log("error",error)
                  
                  // setfilesdata("")
              // setadvertisment("")
              setLoader(false)
            
              setsuccessopenmsg("Something went wrong")
              }
          )
      }
      else{
        
        setsuccessopenmsg("Password did not match")
      }
    
  }

  const [errormsg,seterrormsg]=useState('')
  const [successmsg,setsuccessmsg]=useState('')
  const [isalphanumeric,setisalphanumeric]=useState(false)
  const [isalphanumericonfirm,setisalphanumericonfirm]=useState(false)
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  function setpasswordvalidation(e)
  {
    setpassword(e)
    if (passwordPattern.test(e))
      {
        setisalphanumeric(true)
      }
      else{
        setisalphanumeric(false)
      }
  }

  function setconfirmpasswordvalidation(e)
  {
    setconfirmpassword(e)
    if (passwordPattern.test(e))
      {
        setisalphanumericonfirm(true)
      }
      else{
        setisalphanumericonfirm(false)
      }
  }


  return (
   
<Card className={classes.root}>
{loader && <Spinner></Spinner>}
{(localStorage.getItem("emailId")!='' && localStorage.getItem("emailId")!=null) && <CardContent style={{display:'inline-flex',minHeight:'600px',margin:'5px'}}>
{/* {true && <CardContent style={{display:'inline-flex',minHeight:'600px',margin:'5px'}}> */}

        <div style={{background:'linear-gradient(180deg, #ff0000 0%, #ff0000 100%)',width:'200px',height:'200px',position:'absolute',top:'-5rem',left:'-6.5rem',borderRadius:'50%'}}></div>
        <img src={Botzilla} style={{width:'45%',height:'50%',paddingTop:'5%',paddingLeft:'5%'}}></img>
        
        <Grid item container lg={12} sm={12} md={12} style={{paddingLeft:'15%',display:'block'}}
        className={clsx({
          [classes.paddingtopregister]:!showregister
        })}
        >
        <Typography variant="body2" color="textSecondary" component="p" style={{fontWeight:'900',fontSize:'25px',display:'flex'}}>
        Welcome to <Typography variant="body2" color="textSecondary" component="p" style={{fontWeight:'900',fontSize:'25px',color:'red',paddingLeft:'10px'}}> BOTZILLA</Typography>
        </Typography>
        
        {showregister ?(<>
        
          <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="First Name"
        
        value={firstname}
        onChange={(e)=>setfirstname(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Last Name"
        value={lastname}
        onChange={(e)=>setlastname(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaceIcon />
            </InputAdornment>
          ),
        }}
      />

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Studen ID"
        value={studentId}
        onChange={(e)=>setstudentId(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CardMembershipIcon />
            </InputAdornment>
          ),
        }}
      />

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="password"
        value={emailaddress}
        onChange={(e)=>setemailaddress(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />



<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Phone Number"
        value={phonenumber}
        type='number'
        onChange={handleChange}
        InputProps={{
          maxLength: 10, // HTML5 attribute for input length limit

          startAdornment: (
            <InputAdornment position="start">
              <PhoneIcon />
            </InputAdornment>
          ),
        }}
      />


<FormControl className={classes.formControl}>
      <InputLabel htmlFor="gender-native-simple" style={{ paddingLeft: '10px' }}>Gender</InputLabel>
      <Select
        native
        value={gender}
        onChange={handleChangeGender}
        input={
          <Input
            id="gender-native-simple"
            startAdornment={
              <InputAdornment position="start">
                <WcIcon />
              </InputAdornment>
            }
          />
        }
      >
        <option aria-label="None" value="" />
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </Select>
    </FormControl>

{/* <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Gender"
        value={gender}
        onChange={(e)=>setgender(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WcIcon />
            </InputAdornment>
          ),
        }}
      /> */}

<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Address No 1"
        value={address}
        onChange={(e)=>setaddress(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HomeIcon />
            </InputAdornment>
          ),
        }}
      />


<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="City"
        value={city}
        onChange={(e)=>setcity(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationCityIcon />
            </InputAdornment>
          ),
        }}
      />

{/* <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="State"
        value={state}
        onChange={(e)=>setstate(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PublicIcon />
            </InputAdornment>
          ),
        }}
      /> */}

<FormControl className={classes.formControl1}>
      <InputLabel htmlFor="gender-native-simple" style={{ paddingLeft: '10px' }}>State</InputLabel>
      <Select
        native
        value={state}
        onChange={handleChangeSate}
        input={
          <Input
            id="gender-native-simple"
            startAdornment={
              <InputAdornment position="start">
                <PublicIcon />
              </InputAdornment>
            }
          />
        }
      >
        <option aria-label="None" value="" />
        <option value="Andhra Pradesh">Andhra Pradesh</option>
        <option value="Hyderabad">Hyderabad</option>
      </Select>
    </FormControl>


<TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Zip"
        value={zip}
        type='number'
        onChange={(e)=>setzip(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography style={{display:'grid'}}>

      <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Address No 2"
        value={address1}
        onChange={(e)=>setaddress1(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HomeIcon />
            </InputAdornment>
          ),
        }}
      />


      {/* <TextField
        className={classes.registerpadding}
        id="input-with-icon-textfield"
        label="Password"
        type="password"
        value={passwordregister}
        onChange={(e)=>setpasswordregister(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      /> */}

      </Typography>

<Grid item container lg={12} sm={12} md={12} style={{paddingTop:'2%',display:'grid'}}>
            <Button variant="contained" color="primary"
            
            className={clsx(classes.margin,{
              [classes.opacity]:( firstname=='' || lastname=='' || studentId=='' || emailaddress=='' || phonenumber=='' || gender=='' || address=='' || city=='' || state=='' || zip=='' || address1=='')
            })}
            onClick={(e)=>validateUserRegister(e)}
            >
        Register
      </Button>
</Grid>
{errormsg && <Typography style={{paddingTop:'0px',color:'red'}}>{errormsg}</Typography>}

{successmsg && <Typography style={{paddingTop:'0px',color:'green'}}>{successmsg}</Typography>}


<Typography style={{paddingTop:'10px',display:'grid',float:'left',paddingLeft:'55%',textDecoration:'underline',color:'blue',cursor:'pointer'}} onClick={(e)=>setshowregister(false)}>
        Already Registered ? Login
      </Typography>


        </>):(<>
        <Typography style={{paddingTop:'20px',display:'grid'}}>
        <TextField
        className={classes.margin}
        id="input-with-icon-textfield"
        label="Password"
        value={password}
        type="password"
        onChange={(e)=>setpasswordvalidation(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      </Typography>

      <Typography style={{paddingTop:'50px',display:'grid'}}>
        <TextField
        id="input-with-icon-textfield"
        label="Confirm Password"
        type="password"
        value={confirmpassword}
        
        className={clsx(classes.margin,{
          [classes.opacity]:( !isalphanumeric)
        })}
        onKeyPress={handleKeyPress}
        onChange={(e)=>setconfirmpasswordvalidation(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      </Typography>

     
     <p style={{paddingTop:'5px'}}>Note : Your password must be at least 8 characters long and include at least one letter, one digit, and one special character from @$!%*?&.</p>

        
      <Grid item container lg={12} sm={12} md={12} style={{paddingTop:'5%',display:'grid'}}>
            <Button variant="contained" color="primary"
            
            className={clsx(classes.margin,{
              [classes.opacity]:( !isalphanumeric || !isalphanumericonfirm)
            })}
            onClick={(e)=>validateUser(e)}
            >
        Create Password
      </Button>
     
     <Link to="/login" style={{padding:'10px',marginLeft:'87%'}}>Login</Link>
      {successopenmsg && <Typography style={{paddingTop:'0px'}}
      
      className={clsx({
        [classes.red]:( successopenmsg=="Password did not match" || successopenmsg=="Something went wrong" || successopenmsg=="Password already created" || successopenmsg=="User not found"),
        [classes.green]:( successopenmsg=="Password Created Successfully. You will be automatically redirected to Login Screen in 5 seconds" )
      })}
      >
        {successopenmsg}</Typography>}
      </Grid></>) }
        
       <Grid item container lg={12} sm={12} md={12} style={{paddingTop:'10%',paddingLeft:'50%'}}>
              <Typography style={{paddingTop:'9.5%',paddingRight:'5%'}}>Powered by</Typography>
              <img src={AsoftLogo} style={{width:'50%',height:'10%'}}></img>
        </Grid>

              </Grid>
             
        </CardContent>}
      
        
    </Card>

  );
}
PaswwordPage.propTypes = {
  window: PropTypes.func,
};
export default PaswwordPage;
