import { React, useState, useEffect, useCallback  } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';
import Spinner from './Spinner';
import AsoftLogo from './logo2.png'
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Botzilla from './BotzillaNew1.jpeg'
import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },
  opacity:
  {
    pointerEvents:'none',
    opacity:'0.4'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));


function HomePage(props) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const [filesdata,setfilesdata]=useState("")
  const [advertisment,setadvertisment]=useState('')
  const [loader,setLoader]=useState(false)
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
        // Your code here, e.g., updating state, logging, etc.

        if (localStorage.getItem("role")=="admin")
        {
          navigate('/adminhomescreen');
        }
        else
        {
          navigate('/botzilla');
        }
        
    }, 2000);

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);
}, []); // Empty dependency array means this effect runs once when the component mounts


  return (
   
<Card className={classes.root}>
<Link to="/botzilla"> 
      <CardContent style={{display:'inline-flex'}}>
        <img src={Botzilla} style={{width:'100%',height:'70%',paddingTop:'3%',marginLeft:'20%',marginBottom:'5%'}}></img>
        {/* <Grid item container lg={12} sm={12} md={12} style={{paddingLeft:'10%',display:'block'}}>
        <Typography variant="body2" color="textSecondary" component="p" style={{fontWeight:'900',fontSize:'16px'}}>
        Welcome to Botzilla: Your Comprehensive Academic Assistant
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" style={{paddingTop:'20px',fontSize:'16px'}} >
        Botzilla is an advanced web-based portal meticulously trained on an extensive array of university data, designed to assist students in navigating their academic journey with ease. Whether you need information about your academic details, curriculum specifics, timetable schedules, or teaching staff,
         Botzilla is here to provide accurate and timely answers.
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p" style={{fontWeight:'900',paddingTop:'20px',fontSize:'16px'}}>
        Key Features:
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p" style={{paddingTop:'20px'}}>
       <div style={{display:'flex',fontSize:'16px'}}> <p  style={{fontWeight:'900',fontSize:'16px',textDecoration:'underline'}}>Inquiries:</p> <p style={{fontSize:'16px',paddingLeft:'35px'}}>Instantly access information related to your academic records, enrollment status, and more.</p></div>
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p" >
       <div style={{display:'flex',fontSize:'16px'}}> <p  style={{fontWeight:'900',fontSize:'16px',textDecoration:'underline'}}>Curriculum:</p> <p style={{fontSize:'16px',paddingLeft:'15px'}}>Get comprehensive insights into course structures, syllabi, and prerequisites.</p></div>
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p" >
       <div style={{display:'flex',fontSize:'16px'}}> <p  style={{fontWeight:'900',fontSize:'16px',textDecoration:'underline'}}>Faculty:</p> <p style={{fontSize:'16px',paddingLeft:'50px'}}>Learn more about your professors and teaching assistants, including their office hours and contact details.</p></div>
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p" >
       <div style={{display:'flex',fontSize:'16px'}}> <p  style={{fontWeight:'900',fontSize:'16px',textDecoration:'underline'}}>Integration:</p> <p style={{fontSize:'16px',paddingLeft:'15px'}}>If certain information is not available in the database, Botzilla uses generative AI to pull relevant information from Google, ensuring you always get the answers you need.</p></div>
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p" >
       <div style={{display:'flex',fontSize:'16px'}}> 
       
       Botzilla leverages cutting-edge generative AI technology to provide a seamless and efficient experience. It offers a user-friendly interface and rapid response times, making it your go-to resource for all academic-related queries. Embrace the future of academic assistance with Botzilla and simplify your university life.       </div>
        </Typography>



        </Grid> */}
        </CardContent>
        </Link>
    </Card>

  );
}
HomePage.propTypes = {
  window: PropTypes.func,
};
export default HomePage;
