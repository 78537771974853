import { React, useState, useEffect, useCallback  } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';
import Spinner from './Spinner';
import AsoftLogo from './logo2.png'
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useNavigate } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Botzilla from './BotzillaNew1.jpeg'
import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },
  paddingTop:
  {
    paddingTop:'9% !important'
  },
  root1: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  opacity:
  {
    pointerEvents:'none',
    opacity:'0.4'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));


function AdminHomeScreen(props) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const [filesdata,setfilesdata]=useState("")
  const [advertisment,setadvertisment]=useState('')
  const [loader,setLoader]=useState(false)
  const classes = useStyles();

  const [adminoptions,setadminoptions]=useState([

    {
        'name':'Advertisment Updates',
        'description':'The new advertisement screens for university events and festivals are created to enhance communication, ensuring students, faculty, and visitors stay informed and engaged with dynamic, up-to-date content.',
        'link':'/advertisement',
        'image':'https://thumbs.dreamstime.com/b/advertising-word-cloud-business-concept-56936998.jpg'
    },
    {
        'name':'Weekly / Future Updates',
        'description':'Weekly and future updates will cover exams, placements, internships, and other key university activities, keeping the community well-informed and prepared for upcoming opportunities and important deadlines.',
        'link':'/weeklyupdates',
        'image':'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbC7dFgCjLE_obvCZODW5VDxiHeLaslxk1yQ&s'
    },
    {
        'name':'Last 7 Days Chat History',
        'description':'The chat history from the last 7 days logs all interactions with timestamps, user queries, and responses. This data helps track inquiry patterns, improve response efficiency, and enhance support services.',
        'link':'chathistory',
        'image':'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9UKDRMyn5djWmrUHVm09XFHsS_DrOMjTwRw&s'
    },
    {
        'name':'Generate Analytics of Botzilla',
        'description':'The analytics offer insights into student queries, response times, and types. They help identify common issues, assess support effectiveness, and improve the student experience by addressing needs more efficiently.',
        'link':'analytics',
        'image':'https://blog.turingcollege.com/content/images/2024/04/DALL-E-2024-04-09-14.51.59---Generate-a-landscape-image-of-a-person-sitting-at-a-desk--learning-data-analytics-online.-The-desk-is-cluttered-with-books--papers--and-a-laptop-showi-1.webp'
    },
    {
        'name':'Upload Documents',
        'description':"Uploading documents like FAQs, guidelines, and lecture notes trains the chatbot's AI to understand university topics better. This ensures accurate, relevant responses and improves the chatbot's ability to provide personalized support.",
        'link':'/uploadfile',
        'image':'https://www.bu.edu/files/2023/11/generative-ai_leadin.jpg'
    },
    {
        'name':'Admin Dashboard',
        'description':"The Admin dashboard offers a real-time overview of key metrics, including user activity, query trends, and system performance, helping administrators monitor operations and make informed decisions to improve overall efficiency and effectiveness.",
        'link':'/admindashboard',
        'image':'https://www.lucrative.ai/wp-content/uploads/2021/03/Google-analytics-KPI-1024x620.jpg'
    }
  ])
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setadvertisment(event.target.value);
  };

  const dropzoneStyles = {
    border: '2px dashed #aaa',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    padding:'20px'
  };

  const navigate = useNavigate();

  
  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    navigate('/login');
  };


  const handleDropzoneChange = (files) => {
    // Filter out files that are not JPEG or PNG
    const filteredFiles = files.filter(file => file.type === 'image/png');

    // If more than 2 files are selected, keep only the first 2
    const limitedFiles = filteredFiles.slice(0, 1);

    // Handle the files (e.g., upload or display)
    

    setfilesdata(limitedFiles[0])
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' });

  function downloadfilechat(e)
  {
    setLoader(true)
    
    axiosService.get("downloadHistoryExcelFile?date="+new Date(), {
      responseType: 'blob'
    }).then(
            response => 
            {
              setLoader(false)
              const blob = new Blob([response.data]);
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.setAttribute('download', 'Last7DaysChatHistory.xlsx');
                a.style.display = 'none';
                document.body.appendChild(a);
                
                a.click();
            }
            )
            .catch(error => 
                {
                    console.log("error",error)
                }
            )
  }

  function downloadfile(e)
  {
    
    setLoader(true)
    
    axiosService.get("downloadExcelFile?date="+new Date(), {
      responseType: 'blob'
    }).then(
            response => 
            {
              setLoader(false)
              const blob = new Blob([response.data]);
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.setAttribute('download', 'BotzillaAnalytics.xlsx');
                a.style.display = 'none';
                document.body.appendChild(a);
                
                a.click();
            }
            )
            .catch(error => 
                {
                    console.log("error",error)
                }
            )
  }

  function storeAdvertismentImage(e)
  {
    const formData=new FormData()
    formData.append("images",filesdata)
    setLoader(true)
    axiosService.post("storeadvImage?image_type="+advertisment,formData).then(
      response => 
      {
          
          setLoader(false)
          setfilesdata("")
          setOpen(true)
          // setadvertisment("")
      }
      )
      .catch(error => 
          {
              console.log("error")
              // setfilesdata("")
          // setadvertisment("")
          setLoader(false)

          }
      )

  }
  const drawer = (
    <div>
      {loader && <Spinner></Spinner>}
    <div  />
    <Grid item container lg={12} sm={12} md={12} style={{display:'flex',cursor:'pointer',minHeight:'570px'}} >
    <Grid item lg={12} md={12} sm={12}><Link to="/botzilla" style={{paddingLeft:'50px',paddingBottom:'10px',fontSize:'19px'}} >Back to chat bot</Link></Grid>
    
    </Grid>
    <Divider />
    <Divider />
    <div style={{ flexGrow: 1 }} />
    <ListItem button >
    <img src={AsoftLogo} style={{width:'50%',height:'10%'}}></img>
    </ListItem>
        <ListItem button >
        <ExitToAppIcon></ExitToAppIcon>
        <ListItemText style={{paddingLeft:'10px'}} primary="Logout" onClick={handleLogout}/>
         </ListItem>
    <Divider />
  </div>
);

  return (
    <div className={classes.root}>

<Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Advertisment Image Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          
          Image Uploaded Sucessfully
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Link to="/"> <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
          </Link>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/* <p style={{display:'contents'}}><img src={Botzilla} style={{width:'4%'}}></img></p>  */}
          <Typography variant="h6" noWrap style={{paddingLeft:'10px',display:'contents'}}>
         {/* BOTZILLA */}
         <img src={Botzilla} style={{width:'10%',height:'20%'}}></img>

          </Typography>
         
          {/* <img src={Botzilla} style={{width:'5%',height:'20%',marginLeft:'85%'}}></img> */}
         
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, 
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main>

     {localStorage.getItem("role")=="admin" &&
     
     <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>

        {adminoptions && adminoptions.map((row,index)=>
        (
            <Grid item container lg={4} sm={4} md={4} style={{display:'grid',padding:'20px'
                
            }}
            
            className={clsx({
                [classes.paddingTop]:(index<3)
              })}
            >
               
                
                <Card className={classes.root1}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={row.image}
          title={row.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {row.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" style={{fontSize:'12px'}}>
            {row.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {row.link=="chathistory" && 
        <Button  variant="contained" color="primary" onClick={(e)=>downloadfilechat(e)}>
        Know More
      </Button>
        
        }

{row.link=="analytics" && 
        <Button  variant="contained" color="primary" onClick={(e)=>downloadfile(e)}>
        Know More
      </Button>
        
        }
{(row.link!="chathistory" && row.link!="analytics") && 
      <Link to={row.link}><Button  variant="contained" color="primary">
          Know More
        </Button></Link>}
       
      </CardActions>
    </Card>

    
                </Grid>
        )
        )}

     </Grid>
     }
     
      
      </main>
    </div>
  );
}
AdminHomeScreen.propTypes = {
  window: PropTypes.func,
};
export default AdminHomeScreen;
