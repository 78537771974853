import { React, useState, useEffect, useCallback  } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';
import Spinner from './Spinner';
import AsoftLogo from './logo2.png'
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useNavigate } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Botzilla from './BotzillaNew1.jpeg'
import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },
  opacity:
  {
    pointerEvents:'none',
    opacity:'0.4'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));


function Advertisment(props) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const [filesdata,setfilesdata]=useState("")
  const [advertisment,setadvertisment]=useState('')
  const [loader,setLoader]=useState(false)
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setadvertisment(event.target.value);
  };

  const dropzoneStyles = {
    border: '2px dashed #aaa',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    padding:'20px'
  };

  const navigate = useNavigate();

  
  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    navigate('/login');
  };


  const handleDropzoneChange = (files) => {
    // Filter out files that are not JPEG or PNG
    const filteredFiles = files.filter(file => file.type === 'image/png');

    // If more than 2 files are selected, keep only the first 2
    const limitedFiles = filteredFiles.slice(0, 1);

    // Handle the files (e.g., upload or display)
    

    setfilesdata(limitedFiles[0])
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' });

  function storeAdvertismentImage(e)
  {
    const formData=new FormData()
    formData.append("images",filesdata)
    setLoader(true)
    axiosService.post("storeadvImage?image_type="+advertisment,formData).then(
      response => 
      {
          
          setLoader(false)
          setfilesdata("")
          setOpen(true)
          // setadvertisment("")
      }
      )
      .catch(error => 
          {
              console.log("error")
              // setfilesdata("")
          // setadvertisment("")
          setLoader(false)

          }
      )

  }
  const drawer = (
    <div>
      {loader && <Spinner></Spinner>}
    <div  />
    <Grid item container lg={12} sm={12} md={12} style={{display:'flex',cursor:'pointer',minHeight:'570px'}} >
    <Grid item lg={12} md={12} sm={12}><Link to="/botzilla" style={{paddingLeft:'50px',paddingBottom:'10px',fontSize:'19px'}} >Back to chat bot</Link></Grid>
    
    </Grid>
    <Divider />
    <Divider />
    <div style={{ flexGrow: 1 }} />
    <ListItem button >
    <img src={AsoftLogo} style={{width:'50%',height:'10%'}}></img>
    </ListItem>
        <ListItem button >
        <ExitToAppIcon></ExitToAppIcon>
        <ListItemText style={{paddingLeft:'10px'}} primary="Logout" onClick={handleLogout}/>
         </ListItem>
    <Divider />
  </div>
);

  return (
    <div className={classes.root}>

<Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Advertisment Image Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          
          Image Uploaded Sucessfully
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Link to="/"> <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
          </Link>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/* <p style={{display:'contents'}}><img src={Botzilla} style={{width:'4%'}}></img></p>  */}
          <Typography variant="h6" noWrap style={{paddingLeft:'10px',display:'contents'}}>
         {/* BOTZILLA */}
         <img src={Botzilla} style={{width:'10%',height:'20%'}}></img>

          </Typography>
         
          {/* <img src={Botzilla} style={{width:'5%',height:'20%',marginLeft:'85%'}}></img> */}
         
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, 
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main>

     
      <Grid item container lg={12} md={12} sm={12} style={{display:'flex',paddingTop:'10%'}}>
        <Grid item lg={3} md={3} sm={3} style={{display:'contents'}}>
          <p style={{padding:'20px',paddingTop:'50px'}}>Select Image to Upload</p>
        </Grid>
        <Grid item lg={9} md={9} sm={9} style={{display:'contents'}}>
        <p > 
        

<DropzoneArea
      onChange={handleDropzoneChange}
      acceptedFiles={[ 'image/png']}
      maxFileSize={5000000} // 5 MB
      filesLimit={1}
      dropzoneText=" Drag and drop PNG files here or click to browse "
    />

<p style={{paddingTop:'5px'}}>Note : For optimal display, please ensure the image resolution is 406 x 208 pixels.</p>

    </p>
        </Grid>
        
       
      </Grid>
      <Grid item container lg={12} md={12} sm={12} style={{display:'flex'}}>
      <Grid item lg={3} md={3} sm={3} style={{display:'contents'}}>
          <p style={{padding:'20px',paddingTop:'50px'}}>Select Image Type</p>
        </Grid>
        <Grid item lg={9} md={9} sm={9} style={{display:'grid',paddingLeft:'30px',paddingTop:'40px'}}>
        <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Advertisement Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={advertisment}
          onChange={handleChange}
          label="Advertisement Type"
        >
          
          <MenuItem value="Advertisement1">Advertisement1</MenuItem>
          <MenuItem value="Advertisement2">Advertisement2</MenuItem>
        </Select>
      </FormControl>
          </Grid>
          </Grid>

          <Grid item container lg={12} md={12} sm={12} style={{display:'flex'}}>
      <Grid item lg={5} md={5} sm={5} style={{display:''}}>
          
        </Grid>
        <Grid item lg={6} md={6} sm={6} style={{display:'',paddingLeft:'30px',paddingTop:'40px'}}>
                  <Button variant="contained" color="primary"
                  
                  className={clsx(classes.margin,{
                    [classes.opacity]:(advertisment=='' || filesdata=='' || filesdata==undefined)
                  })}
                  onClick={(e)=>storeAdvertismentImage(e)}
                  >
            Submit
          </Button>
          </Grid>
          </Grid>
      </main>
    </div>
  );
}
Advertisment.propTypes = {
  window: PropTypes.func,
};
export default Advertisment;
