import React from "react";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import LandingPage from "./components/LandingPage";
import Advertisment from "./components/Advertisment";
import FutureUpdates from "./components/FutureUpdates";
import HomePage from "./components/HomePage";
import DocumentUpload from "./components/DocumentUpload";
import LoginPage from "./components/LoginPage";
import PaswwordPage from "./components/PasswordPage";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import AdminDashboard from "./components/AdminDashboard";
import AdminHomeScreen from "./components/AdminHomeScreen";
import "./Edtech.css";

const PrivateRoute = ({ element }) => {
  const isAuthenticated = !!localStorage.getItem("username");
  return isAuthenticated ? element : <Navigate to="/login" />;
};

function App() {
  const RoutingData = () => {
    let routes = useRoutes([
      { path: "/", element: <PrivateRoute element={<HomePage />} /> },
      { path: "/login", element: <LoginPage /> },
      { path: "/forgotpassword", element: <ForgotPasswordPage /> },
      { path: "/createpassword", element: <PaswwordPage /> },
      { path: "/botzilla", element: <PrivateRoute element={<LandingPage />} /> },
      { path: "/advertisement", element: <PrivateRoute element={<Advertisment />} /> },
      { path: "/uploadfile", element: <PrivateRoute element={<DocumentUpload />} /> },
      { path: "/weeklyupdates", element: <PrivateRoute element={<FutureUpdates />} /> },
      { path: "/admindashboard", element: <PrivateRoute element={<AdminDashboard />} /> },
      { path: "/adminhomescreen", element: <PrivateRoute element={<AdminHomeScreen />} /> },
    ]);
    return routes;
  };

  return (
    <div className="App">
      <Router>
        <RoutingData />
      </Router>
    </div>
  );
}

export default App;
